<template>
    <!-- 业主缴费订单-->
    <div>
        <div class="table_content">
            <el-table
                    v-loading="loading"
                    element-loading-text="拼命加载中"
                    :data="tableData"
                    style="width:100%"
                    border
                    :default-sort="{prop: 'settingTime', order: 'descending'}"
                    :stripe="true">
                <el-table-column fixed prop="ownerName" label="业主姓名">
                </el-table-column>
                <el-table-column fixed prop="houseAmt" label="分摊金额">
                </el-table-column>
                <el-table-column prop="orderState" label="订单状态" :formatter="formatOrderState">
                </el-table-column>
                <el-table-column fixed="right" label="操作">
                    <template slot-scope="scope">
                        <el-button
                                size="mini"
                                @click="pay(scope.row)">缴费
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    background
                    style="margin-top: 50px"
                    layout="prev, pager, next"
                    :page-size="15"
                    @current-change="handleCurrentChange"
                    :total="totalNum">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ownerExpendOrder",
        data() {
            return {
                url: this.$Config.base_server,
                loading: false,
                totalNum: 15,
                tableData: [],
            }
        }, mounted() {
            this.common();
        }, methods: {
            common(currentPage = 0) {
                this.loading = true;
                //初始化数据
                const data = {
                    page: currentPage,
                    limit: 15
                };
                const that = this;
                const URL = that.url + "/api-public/busiorder/detaList?access_token=" + localStorage.getItem("token");
                $.ajax({
                    url: URL,
                    contentType: "application/json",
                    type: "get",
                    data: data,
                    success: function (res) {
                        that.loading = false;
                        if (res.code == 0) {
                            that.totalNum = res.count;
                            that.tableData = res.data;
                        }

                    },
                    error: function (res) {
                        that.loading = false;
                    }
                })
            }, handleCurrentChange(val) {//当前页数改变
                this.common(val)
            }, pay(row) {
                //缴费按钮

            }, formatOrderState(row) {
                if (row.orgStat == 1) {
                    return "生成";
                } else if (row.orgStat == 2) {
                    return "可缴费";
                } else if (row.orgStat == 3) {
                    return "已消费";
                } else if (row.orgStat == 4) {
                    return "作废";
                } else if (row.orgStat == 5) {
                    return "已分摊";
                }
            }
        }
    }
</script>

<style scoped>

</style>